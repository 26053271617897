import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Container, Logo, CompanyName } from './LogoNameInline.style';
import LogoImage from 'assets/logo.svg';

export enum Size {
  _16 = 2,
  _24 = 3,
}

interface Props {
  size: Size;
}

const LogoNameInline: React.FC<Props> = ({ size = Size._16 }) => {
  const intl = useIntl();

  return (
    <Container>
      <Logo
        size={size}
        src={LogoImage}
        alt={intl.formatMessage({ id: 'logo-alt' })}
      />
      <CompanyName size={size}>
        <FormattedMessage id="company-name" />
      </CompanyName>
    </Container>
  );
};

export default LogoNameInline;
