import { css, FlattenSimpleInterpolation } from 'styled-components';
import { CalendarColor } from 'ressources/calendar/class';

const SPACING_UNIT = 8;
const MEASUREMENT_UNIT = 'px';

export const getPixels = (multiplier: number): number =>
  multiplier * SPACING_UNIT;

export const getSpacing = (multiplier: number): string =>
  `${getPixels(multiplier)}${MEASUREMENT_UNIT}`;

export const font = {
  size24: css`
    font-size: 24px;
    line-height: 28px;
  `,
  size16: css`
    font-size: 16px;
    line-height: 24px;
  `,
  size14: css`
    font-size: 14px;
    line-height: 16px;
  `,
  size12: css`
    font-size: 12px;
    line-height: 14px;
  `,
  size9: css`
    font-size: 9px;
    line-height: 11px;
  `,
  size8: css`
    font-size: 8px;
    line-height: 9px;
  `,
};

export const colors = {
  activeBlue: '#1588F1',
  lightBlue: '#F8FBFF',
  black: '#2A2A2A',
  gray1: '#BEBEBE',
  gray0: '#DEDEDE',
  gray_1: '#EEEEEE',
  gray_2: '#F8F8F8',
  white: '#FFFFFF',
  red: '#FC4166',
};

export type ColorSet = {
  text: string;
  background: string;
  selectedBackground: string;
};

export const projectColorsDetails: {
  [key in CalendarColor]: ColorSet;
} = {
  [CalendarColor.blue]: {
    text: '#0077BE', // base color
    background: '#D3EBFE', // base color
    selectedBackground: '#36B5F8', // base color
  },
  [CalendarColor.green]: {
    text: '#4B942E', // base color
    background: '#DAF6D3', // base color
    selectedBackground: '#66DB43', // base color
  },
  [CalendarColor.red]: {
    text: '#D2496D', // base color
    background: '#FECCDA', // base color
    selectedBackground: '#FC4166', // base color
  },
  [CalendarColor.yellow]: {
    text: '#A68011', // base color
    background: '#FFF1CE', // base color
    selectedBackground: '#FDCA33', // base color
  },
  [CalendarColor.orange]: {
    text: '#BC702E', // base color
    background: '#FEE2CF', // base color
    selectedBackground: '#FD8F2F', // base color
  },
  [CalendarColor.purple]: {
    text: '#8A449C', // base color
    background: '#EED8F6', // base color
    selectedBackground: '#C469DD', // base color
  },
  [CalendarColor.brown]: {
    text: '#90724C', // base color
    background: '#E5DFD9', // base color
    selectedBackground: '#9B8060', // base color
  },
};

export const outline = (): FlattenSimpleInterpolation => css`
  :focus {
    outline: ${colors.activeBlue} solid 1px;
    outline-offset: 2px;
  }
`;

export const noSelect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;
