import { rowPixels, columnPixels, columnSize } from 'configuration';
import { getSpacing } from 'stylesheet';
import { DateNoTime } from 'services/date';

export const getLineFromY = (timelineY: number, y: number): number =>
  Math.trunc((y - timelineY) / rowPixels);

export const getAmountOfDaysFromX = (timelineX: number, x: number): number =>
  Math.trunc((x - timelineX) / columnPixels);

export const getDateFromTimelineAndMousePosition = ({
  firstDayOfTimeline,
  timelineX,
  mouseX,
}: {
  firstDayOfTimeline: DateNoTime;
  timelineX: number;
  mouseX: number;
}): DateNoTime => {
  return DateNoTime.addDays(
    firstDayOfTimeline,
    getAmountOfDaysFromX(timelineX, mouseX),
  );
};

export const getSortedDates = (
  firstDate: DateNoTime,
  secondDate: DateNoTime,
): DateNoTime[] => {
  const dates = [firstDate, secondDate];

  return dates.sort((d1, d2) =>
    DateNoTime.isFirstDateNewerThanSecondDate(d1, d2) ? 1 : -1,
  );
};

export const updateProjectStyle = (
  firstDayOfTimeline: DateNoTime,
  start: DateNoTime,
  end: DateNoTime,
  project: HTMLDivElement,
): void => {
  const duration = DateNoTime.differenceInDays(end, start) + 1;

  project.style.width = getSpacing(duration * columnSize);
  project.style.left = getSpacing(
    DateNoTime.differenceInDays(start, firstDayOfTimeline) * columnSize,
  );
};
