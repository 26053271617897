import { get, post } from 'services/networking/client';
import { Calendar } from 'ressources/calendar/class';

export const fetchCalendars = async (): Promise<Calendar[]> => {
  const response = await get<Calendar[]>(
    `${process.env.REACT_APP_BACKEND_URL}/calendars`,
  );

  return response.data;
};

export const createCalendar = async (calendar: Calendar): Promise<void> => {
  await post<Calendar, void>(
    `${process.env.REACT_APP_BACKEND_URL}/calendars`,
    calendar,
  );
};
