import React, { useRef, MouseEvent as ReactMouseEvent, useEffect } from 'react';

import {
  addBarContainer,
  addBar,
  scrollBarOnDrag,
  addContentWidth,
} from './states/scrollState';

import {
  PaddingContainer,
  Container,
  BarContainer,
  Bar,
  BarGrab,
} from './Scrollbar.style';

interface Props {
  contentWidth: number;
}

const Scrollbar: React.FC<Props> = ({ contentWidth }) => {
  addContentWidth(contentWidth);

  const containerX = useRef<number>(0);
  const distanceBetweenBarStartAndMouse = useRef<number>(0);

  const barContainerRef = useRef<HTMLDivElement | null>(null);
  const barRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (barRef.current && barContainerRef.current) {
      addBar(barRef.current);
      addBarContainer(barContainerRef.current);
    }
  }, [barRef.current, barContainerRef.current]);

  const onMouseDown = (e: ReactMouseEvent) => {
    if (!barRef.current || !barContainerRef.current) {
      return;
    }
    const mouseInitialX = e.clientX;
    const barInitialX = barRef.current.getBoundingClientRect().x;
    distanceBetweenBarStartAndMouse.current = mouseInitialX - barInitialX;
    containerX.current = barContainerRef.current.getBoundingClientRect().x;

    document.addEventListener('mousemove', mouseMoveEventListener);
    document.addEventListener('mouseup', mouseUpEventListener);
  };

  const mouseMoveEventListener = (e: MouseEvent) => {
    if (!barContainerRef.current || !barRef.current) {
      return;
    }

    const scrollLeft =
      e.clientX - distanceBetweenBarStartAndMouse.current - containerX.current;

    scrollBarOnDrag(scrollLeft);
  };

  const mouseUpEventListener = () => {
    if (!barContainerRef.current || !barRef.current) {
      return;
    }
    document.removeEventListener('mousemove', mouseMoveEventListener);
    document.removeEventListener('mouseup', mouseUpEventListener);
  };

  return (
    <PaddingContainer>
      <Container ref={barContainerRef}>
        <BarContainer ref={barRef}>
          <Bar />
          <BarGrab onMouseDown={onMouseDown} />
        </BarContainer>
      </Container>
    </PaddingContainer>
  );
};

export default Scrollbar;
