import React from 'react';
import { ProjectBackend } from './class';

type State = {
  projects: ProjectBackend[];
};

const state: State = {
  projects: [],
};

type StateSubscriber = React.Dispatch<React.SetStateAction<ProjectBackend[]>>;

const stateSubscribers: Record<number, StateSubscriber> = {};

const callSubscribers = (): void => {
  Object.values(stateSubscribers).forEach((stateSubscriber) => {
    stateSubscriber(state.projects);
  });
};

export const addProjectDataStateSubscriber = (
  stateSubscriber: StateSubscriber,
  timelinePosition: number,
): void => {
  stateSubscribers[timelinePosition] = stateSubscriber;

  // When a subscriber is added, we update it with current state so that he is up-to-date
  stateSubscriber(state.projects);
};

const getProjects = (): ProjectBackend[] => state.projects;

export const updateProjects = (projects: ProjectBackend[]): void => {
  state.projects = projects;
  callSubscribers();
};

export const addProject = (project: ProjectBackend): void => {
  updateProjects([...getProjects(), project]);
};

export const replaceProject = (
  projectId: string,
  newProject: ProjectBackend,
): void => {
  updateProjects(
    getProjects().map((project) =>
      project.id === projectId ? newProject : project,
    ),
  );
};

export const removeProject = (projectId: string): void => {
  updateProjects(getProjects().filter((project) => project.id !== projectId));
};
