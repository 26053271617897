import { absoluteScroll } from 'pages/Planner/states/horizontalScroll/state';
import { allContainerWidth } from 'pages/Planner/states/timelines/state';

type ScrollState = {
  bar: HTMLDivElement | null;
  barContainer: HTMLDivElement | null;
  contentWidth: number;
};

const state: ScrollState = {
  bar: null,
  barContainer: null,
  contentWidth: 0,
};

export const addContentWidth = (contentWidth: number): void => {
  state.contentWidth = contentWidth;
};

export const addBar = (element: HTMLDivElement): void => {
  state.bar = element;
};

export const addBarContainer = (element: HTMLDivElement): void => {
  state.barContainer = element;
};

export const scrollBarOnDrag = (scrollBarLeft: number): void => {
  if (!state.barContainer) return;

  const mainScrollLeft = Math.round(
    (state.contentWidth * scrollBarLeft) / state.barContainer.offsetWidth,
  );

  absoluteScroll(mainScrollLeft);
};

export const scrollScrollBar = (scrollLeft: number): void => {
  if (state.bar && state.barContainer) {
    state.bar.style.left = `${
      (scrollLeft * state.barContainer.offsetWidth) / state.contentWidth
    }px`;
  }
};

//--------------------------------------------------------

const getScrollbarRatio = (): number =>
  allContainerWidth() / state.contentWidth;

export const updateScrollbarRatio = (): void => {
  if (!state.barContainer || !state.bar) return;

  state.bar.style.width = `${
    state.barContainer.offsetWidth * getScrollbarRatio()
  }px`;
};
