import React from 'react';

import { Container, TodayContainer, Text } from './DayLabel.style';
import { DateNoTime } from 'services/date';

interface Props {
  day: DateNoTime;
}

const DayLabel: React.FC<Props> = ({ day }) => {
  const getLabel = () => <Text>{day.day}</Text>;

  return (
    <Container
      isWeekendDay={day.isWeekendDate()}
      isFirstDayOfMonth={day.isFirstDateOfMonth()}
    >
      {day.isToday() ? (
        <TodayContainer>{getLabel()}</TodayContainer>
      ) : (
        getLabel()
      )}
    </Container>
  );
};

export default DayLabel;
