import React, { useEffect, useState } from 'react';

import { addProjectDataStateSubscriber } from 'ressources/project/serverState';
import { ProjectBackend } from 'ressources/project/class';

import ProjectWithPopin from './components/ProjectWithPopin';
import CreationInitialisation from './components/Animations/CreationInitialisation';

import { FrontendId, Project, ProjectStatus } from './class';

import { addSelectionStateSubscriber } from './selectionState/state';
import { DateNoTime } from 'services/date';

interface Props {
  firstDayOfLine: DateNoTime;
  columnsElement: HTMLDivElement;
  timelinePosition: number;
}

const ProjectsDisplay: React.FC<Props> = ({
  firstDayOfLine,
  columnsElement,
  timelinePosition,
}) => {
  const [backendProjects, setBackendProjects] = useState<ProjectBackend[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<FrontendId | null>(
    null,
  );
  const [popinProjectId, setPopinProjectId] = useState<FrontendId | null>(null);

  useEffect(() => {
    addSelectionStateSubscriber(
      { setSelectedProjectId, setPopinProjectId },
      timelinePosition,
    );
    addProjectDataStateSubscriber(setBackendProjects, timelinePosition);
  }, []);

  const projects: Project[] = backendProjects
    .map((backendProject) => {
      return Project.fromProjectBackend(
        backendProject,
        ProjectStatus.NOT_SELECTED,
        timelinePosition,
      );
    })
    .map((project: Project): Project => {
      project.status = (() => {
        if (popinProjectId?.equalsFrontendId(project.getFrontendId())) {
          return ProjectStatus.POPIN;
        }

        if (
          selectedProjectId?.getId() === project.id ||
          popinProjectId?.getId() === project.id
        ) {
          return ProjectStatus.SELECTED;
        }

        return ProjectStatus.NOT_SELECTED;
      })();

      return project;
    });

  return (
    <>
      {projects.map((project) => (
        <ProjectWithPopin
          key={project.id}
          firstDayOfLine={firstDayOfLine}
          project={project}
          columnsElement={columnsElement}
        />
      ))}
      <CreationInitialisation
        firstDayOfLine={firstDayOfLine}
        columnsElement={columnsElement}
        timelinePosition={timelinePosition}
      />
    </>
  );
};

export default ProjectsDisplay;
