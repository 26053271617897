import styled, { css } from 'styled-components';
import { getSpacing, projectColorsDetails, font, colors } from 'stylesheet';
import { rowSize, columnSize, rowSizeSpacing } from 'configuration';
import { ProjectStatus } from 'components/specifics/Timeline/components/ProjectsDisplay/class';
import { CalendarColor } from 'ressources/calendar/class';

export const TransparentContainer = styled.div.attrs<{
  line: number;
  daysBeforeProject: number;
  duration: number;
}>(({ line, daysBeforeProject, duration }) => ({
  style: {
    top: getSpacing(line * rowSize),
    left: getSpacing(daysBeforeProject * columnSize),
    width: getSpacing(duration * columnSize),
  },
}))<{
  line: number;
  daysBeforeProject: number;
  duration: number;
}>`
  position: absolute;
  height: ${rowSizeSpacing};
`;

export const Container = styled.div<{
  color: CalendarColor;
  status: ProjectStatus;
}>`
  width: calc(100% - 1px);
  //todo : getSpacing à arranger ou appli à zoomer
  height: ${getSpacing(2.25)};
  box-sizing: border-box;

  background-color: ${({ color, status }) =>
    status !== ProjectStatus.NOT_SELECTED
      ? projectColorsDetails[color].selectedBackground
      : projectColorsDetails[color].background};

  ${({ status, color }) =>
    status === ProjectStatus.NOT_SELECTED &&
    css`
      border: 1px solid ${projectColorsDetails[color].text};
    `};

  //todo : getSpacing à arranger ou appli à zoomer
  border-radius: ${getSpacing(0.5)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${getSpacing(0.5)};
`;

export const LeftGrip = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 4px;
  cursor: ew-resize;
`;

export const RightGrip = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 4px;
  cursor: ew-resize;
`;

export const Title = styled.p<{
  color: CalendarColor;
  status: ProjectStatus;
}>`
  left: ${getSpacing(0.25)};
  position: sticky;
  position: -webkit-sticky;
  overflow: visible;
  white-space: nowrap;
  color: ${({ color, status }) =>
    status !== ProjectStatus.NOT_SELECTED
      ? colors.white
      : projectColorsDetails[color].text};
  ${font.size9};
`;
