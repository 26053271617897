import React, { useState, useRef, useEffect } from 'react';

import {
  Project,
  ProjectStatus,
} from 'components/specifics/Timeline/components/ProjectsDisplay/class';

import EditableProject from './components/EditableProject';
import ProjectPopin from './components/ProjectPopin';
import { CalendarColor } from 'ressources/calendar/class';
import { DateNoTime } from 'services/date';

interface Props {
  firstDayOfLine: DateNoTime;
  project: Project;
  columnsElement: HTMLDivElement;
}

const ProjectWithPopin: React.FC<Props> = ({
  firstDayOfLine,
  project,
  columnsElement,
}) => {
  const [title, setTitle] = useState<string>(project.title);
  const [color, setColor] = useState<CalendarColor>(
    project.getCalendar().color,
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setColor(project.getCalendar().color);
    setTitle(project.title);
  }, [project.title, project.getCalendar().color]);

  return (
    <>
      <EditableProject
        project={
          new Project(
            project.id,
            title,
            project.line,
            project.start,
            project.end,
            project.calendarId,
            project.status,
            project.timeline,
          )
        }
        color={color}
        firstDayOfTimeline={firstDayOfLine}
        projectRef={ref}
        columnsElement={columnsElement}
      />
      {project.status === ProjectStatus.POPIN && (
        <ProjectPopin
          barRef={ref}
          project={project}
          title={title}
          setTitle={setTitle}
          color={color}
          setColor={setColor}
        />
      )}
    </>
  );
};

export default ProjectWithPopin;
