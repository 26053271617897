import React, { useEffect, useState } from 'react';

import { fetchPlannerData } from 'ressources/initialization';

import Header from 'components/specifics/Header';
import Scrollbar from 'components/specifics/Scrollbar';
import Timeline from 'components/specifics/Timeline';
import { unselectProjects } from 'components/specifics/Timeline/components/ProjectsDisplay/selectionState/interface';
import { updateScrollbarRatio } from 'components/specifics/Scrollbar/states/scrollState';
import { MonthClass } from 'components/specifics/Timeline/components/Month/class';

import {
  handleScrollTopEvent,
  useRegisterInVerticalScrollState,
} from './states/verticalScroll/state';
import { updateAllContentMasks } from './states/timelines/state';
import { useRegisterHorizontalScrollAndTimelines } from './states/registerHorizontalScrollAndTimelines';
import { useTouch } from './states/touch/state';

import AddTimelineButtons from './components/AddTimelineButtons';

import { onKeydown } from './utils/keyboard';
import { initialMonths } from 'pages/Planner/utils/daysAndMonths';
import { Container, ScrollableContent, Content } from './Planner.style';
import {
  relativeScroll,
  currentScroll,
} from 'pages/Planner/states/horizontalScroll/state';

const onMainWheel = (e: WheelEvent): void => {
  unselectProjects();
  e.preventDefault();
  relativeScroll(e.deltaX);
  handleScrollTopEvent(e.deltaY);
};

export const updateDimensionsThenScroll = (): void => {
  updateAllContentMasks();
  updateScrollbarRatio();
  currentScroll();
};

const Planner: React.FC = () => {
  // ----------------------------- Events -----------------------------

  useTouch();

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    window.addEventListener('resize', updateDimensionsThenScroll);
    document.addEventListener('wheel', onMainWheel, { passive: false });
    return () => {
      document.removeEventListener('keydown', onKeydown);
      window.removeEventListener('resize', updateDimensionsThenScroll);
      document.removeEventListener('wheel', onMainWheel);
    };
  }, []);

  // ----------------------------- states -----------------------------

  const [months, setMonths] = useState<MonthClass[]>(initialMonths);

  const [timelineList, setTimelineList] = useState<number[]>([0, 1]);

  // ------------------- shared states registration -------------------

  const [days, contentWidth] = useRegisterHorizontalScrollAndTimelines(
    months,
    setMonths,
  );

  const timelinesContainer = useRegisterInVerticalScrollState();

  // -------------------------- Initialization --------------------------

  useEffect(() => {
    fetchPlannerData();
  }, []);

  // --------------------------------------------------------------------

  return (
    <Container onMouseDown={unselectProjects}>
      <Header />
      <Scrollbar contentWidth={contentWidth} />
      <ScrollableContent ref={timelinesContainer}>
        <Content>
          {timelineList.map((timelinePosition) => (
            <Timeline
              key={timelinePosition}
              months={months}
              days={days}
              position={timelinePosition}
            />
          ))}
          <AddTimelineButtons
            timelineList={timelineList}
            setTimelineList={setTimelineList}
          />
        </Content>
      </ScrollableContent>
    </Container>
  );
};

export default Planner;
