import styled from 'styled-components';
import {
  colors,
  font,
  getSpacing,
  outline,
  projectColorsDetails,
} from 'stylesheet';
import { CalendarColor } from 'ressources/calendar/class';
import Popin from 'components/generics/Popin';
import { ReactComponent as Pen } from 'assets/pen.svg';

export const Container = styled(Popin)`
  padding: ${getSpacing(1)};
  width: ${getSpacing(27)};
  z-index: 1;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${getSpacing(2)};
`;

export const TitleInput = styled.input`
  flex: 1;
  height: ${getSpacing(2)};
  ${font.size12};
`;

export const CalendarColorCircle = styled.button<{ color: CalendarColor }>`
  ${outline()};
  background-color: ${({ color }) =>
    projectColorsDetails[color].selectedBackground};
  height: ${getSpacing(3)};
  width: ${getSpacing(3)};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PenIcon = styled(Pen)`
  height: 12px;
  color: ${colors.white};
`;
