import { DateNoTime, to2Digits } from 'services/date';

export class MonthClass {
  year: number;

  // month in javascript style 0 = january, 11 = december
  month: number;

  constructor(year: number, month: number) {
    this.year = year;
    this.month = month;
  }

  getFirstDay(): DateNoTime {
    return new DateNoTime(this.year, this.month, 1);
  }

  getLastDay(): DateNoTime {
    return DateNoTime.getLastDateOfMonth(this.year, this.month);
  }

  toString(): string {
    return `${this.year}-${to2Digits(this.month + 1)}`;
  }

  getNextMonth(): MonthClass {
    return MonthClass.addMonths(this, 1);
  }

  static addMonths(month: MonthClass, amount: number): MonthClass {
    const currentMonthFirstDay = new DateNoTime(month.year, month.month, 1);
    const nextMonthFirstDay = DateNoTime.addMonths(
      currentMonthFirstDay,
      amount,
    );

    return new MonthClass(nextMonthFirstDay.year, nextMonthFirstDay.month);
  }

  equalsMonth(month: MonthClass): boolean {
    return month.month === this.month && month.year === this.year;
  }

  getAmountOfDays(): number {
    return this.getLastDay().day;
  }
}
