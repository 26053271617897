import {
  getPopinProjectId,
  getSelectedProjectId,
  updateSelectedProject,
} from './state';
import { FrontendId, ProjectStatus } from '../class';

export const selectProject = (id: FrontendId): void => {
  updateSelectedProject(id, null);
};

export const showPopin = (id: FrontendId): void => {
  updateSelectedProject(null, id);
};

export const unselectProjects = (): void => {
  updateSelectedProject(null, null);
};

export const updateSelectedProjectStatus = (status: ProjectStatus): void => {
  switch (status) {
    case ProjectStatus.POPIN:
      updateSelectedProject(null, getSelectedProjectId());
      break;
    case ProjectStatus.SELECTED:
      updateSelectedProject(getPopinProjectId(), null);
      break;
    case ProjectStatus.NOT_SELECTED:
      return;
  }
};
