import {
  FrontendId,
  ProjectStatus,
} from 'components/specifics/Timeline/components/ProjectsDisplay/class';
import React from 'react';

type State = {
  selectedProjectId: FrontendId | null;
  popinProjectId: FrontendId | null;
};

const state: State = {
  selectedProjectId: null,
  popinProjectId: null,
};

type StateSubscriber = {
  setSelectedProjectId: React.Dispatch<React.SetStateAction<FrontendId | null>>;
  setPopinProjectId: React.Dispatch<React.SetStateAction<FrontendId | null>>;
};

const stateSubscribers: Record<number, StateSubscriber> = {};

const callSubscriber = (stateSubscriber: StateSubscriber) => {
  stateSubscriber.setSelectedProjectId(state.selectedProjectId);
  stateSubscriber.setPopinProjectId(state.popinProjectId);
};

const callSubscribers = (): void => {
  Object.values(stateSubscribers).forEach((stateSubscriber) => {
    callSubscriber(stateSubscriber);
  });
};

export const addSelectionStateSubscriber = (
  stateSubscriber: StateSubscriber,
  timelinePosition: number,
): void => {
  stateSubscribers[timelinePosition] = stateSubscriber;

  // When a subscriber is added, we update it with current state so that he is up-to-date
  callSubscriber(stateSubscriber);
};

export const getSelectedProjectId = (): FrontendId | null =>
  state.selectedProjectId;

export const getPopinProjectId = (): FrontendId | null => state.popinProjectId;

export const getSelectedProjectStatus = (): ProjectStatus => {
  if (state.selectedProjectId) {
    return ProjectStatus.SELECTED;
  }

  if (state.popinProjectId) {
    return ProjectStatus.POPIN;
  }

  return ProjectStatus.NOT_SELECTED;
};

export const updateSelectedProject = (
  selectedProjectId: FrontendId | null,
  popinProjectId: FrontendId | null,
): void => {
  state.selectedProjectId = selectedProjectId;
  state.popinProjectId = popinProjectId;
  callSubscribers();
};
