import { get } from 'services/networking/client';
import { Board } from './class';

export const fetchBoards = async (): Promise<Board[]> => {
  const response = await get<Board[]>(
    `${process.env.REACT_APP_BACKEND_URL}/boards`,
  );

  return response.data;
};
