const computeContentMaskWidth = (
  contentWidth: number,
  numberOfTimelines: number,
  containerWidth: number,
): number => {
  return contentWidth - (numberOfTimelines - 1) * containerWidth;
};

const computeContentOffsetLeft = (
  position: number,
  containerWidth: number,
): number => {
  return -position * containerWidth;
};

export const updateContentMask = (
  content: HTMLDivElement,
  contentMask: HTMLDivElement,
  container: HTMLDivElement,
  numberOfTimelines: number,
  position: number,
  contentWidth: number,
): void => {
  contentMask.style.width = `${computeContentMaskWidth(
    contentWidth,
    numberOfTimelines,
    container.offsetWidth,
  )}px`;
  content.style.marginLeft = `${computeContentOffsetLeft(
    position,
    container.offsetWidth,
  )}px`;
};
