import { MonthClass } from 'components/specifics/Timeline/components/Month/class';
import { DateNoTime } from 'services/date';

export const getMonths = (
  firstMonth: MonthClass,
  lastMonth: MonthClass,
): MonthClass[] => {
  const months: MonthClass[] = [];

  let currentMonth = firstMonth;

  while (!currentMonth.equalsMonth(lastMonth)) {
    months.push(currentMonth);
    currentMonth = currentMonth.getNextMonth();
  }

  months.push(lastMonth);

  return months;
};

export const getDays = (months: MonthClass[]): DateNoTime[] => {
  const firstDay = months[0].getFirstDay();
  const lastDay = months[months.length - 1].getLastDay();

  const days: DateNoTime[] = [];
  let currentDay = firstDay;

  while (!DateNoTime.equals(currentDay, lastDay)) {
    days.push(currentDay);
    currentDay = currentDay.getNextDate();
  }

  days.push(lastDay);

  return days;
};

const getInitialMonths = (): MonthClass[] => {
  const today: DateNoTime = DateNoTime.getToday();

  const sixMonthAgo = DateNoTime.addMonths(today, -6);
  const oneYearFromNow = DateNoTime.addYears(today, 1);

  return getMonths(
    new MonthClass(sixMonthAgo.year, sixMonthAgo.month),
    new MonthClass(oneYearFromNow.year, oneYearFromNow.month),
  );
};

export const initialMonths = getInitialMonths();
